<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">赞和收藏</div>
    </div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        offset="800"
        @load="onLoad"
      >
    <div class="container" v-for="item in statList" :key="item.id">
      <div class="contentHearName">
        <div class="contentHear" @click="gotrends()"><img :src="item.headImg" alt=""></div>
        <div class="contentNameTime">
          <div>{{item.usrName}}</div>
          <div>{{item.updateTime}}</div>
        </div>
      </div>
      <div class="current" v-if="item.operateType === 0">
        点赞了这条圈子 <img src="../../image/quanz/ydz.png" alt="" />
      </div>
         <div class="current" v-if="item.operateType === 1">
        收藏了这条圈子 <img src="../../image/quanz/ysc.png" alt="" />
      </div>
      <div class="contentBox" @click="goTrendsDetail(item)">
        <div>
          {{item.content}}
        </div>
      </div>
    </div>
      </van-list>
    </van-pull-refresh>
    <!-- <div class="container">
      <div class="contentHearName">
        <div class="contentHear"></div>
        <div class="contentNameTime">
          <div>低调先生</div>
          <div>2022-06-04 11:47</div>
        </div>
      </div>
      <div class="current">
        收藏了这条圈子 <img src="../../image/quanz/ysc.png" alt="" />
      </div>
      <div class="contentBox">
        <div>月。故国神</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { businessStat } from "../../api/circle";
export default {
  data() {
    return {
      statList:[],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      isLoading: false,
      loading: false, //上拉加载
      finished: false, //加载与不加载的状态
    };
  },
  mounted() {
    businessStat({
      business:2,
      types: "0,1",
      pageNum: 1,
      pageSize: 10,
    }).then((res) => {
      console.log(res, "赞和收藏");
      this.statList = res.data.data.items;
      this.total = res.data.data.total;

    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 跳转动态
    gotrends() {
      this.$router.push({ path: "/TrendIndex" });
    },
    // 跳转详情
    goTrendsDetail(item) {
      console.log(item);
      this.$router.push({ path: "/trendsDetail", query: {id: item.infoId} });
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        businessStat({
          business:2,
          types: "0,1",
          pageNum: 1,
          pageSize: this.pageSize,
        }).then((res) => {
          this.statList = res.data.data.items;
          this.total = res.data.data.total;
        });
      }, 1000);
    },
    onLoad() {
      businessStat({
        business:2,
        types: "0,1",
        pageNum: this.pageNum + 1,
        pageSize: this.pageSize,
      }).then((res) => {
        this.statList = this.statList.concat(res.data.data.items);
        this.total = res.data.data.total;
        if (res.data.data.items.length < this.pageSize) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 60px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 236px;
}
.container {
  margin-left: 32px;
}
.contentHearName {
  display: flex;
}
.contentHear img{
  width: 80px;
  height: 80px;
  /* background: red; */
  border-radius: 50%;
}
.contentNameTime {
  margin-left: 20px;
}
.contentNameTime div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.contentNameTime div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.current {
  display: flex;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-top: 32px;
}
.current img {
  width: 40px;
  height: 40px;
  margin-left: 16px;
}
.contentBox {
  width: 686px;
  /* height: 176px; */
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 38px;
  overflow: hidden;
  margin-bottom: 80px;
  padding-bottom: 8px;
}

.contentBox div {
  width: 622px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-top: 16px;
  line-height: 46px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
